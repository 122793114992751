<ng-container *ngIf="blocked$ | async as type">
    <ng-container *ngIf="id$ | async">
        <div *ngIf="isClosed === false && (type === 'Ship' || type === 'Invoice')" @Grow class="grow mb-5">
            <div class="border-b-2 border-danger-400 box p-5 bg-danger-100 rounded-2xl">
                <div class="flex content-center mb-4">
                    <span class="grow text-base font-semibold text-gray-800" [innerHTML]="'blockades.' + type + '.title' | translate"></span>
                    <app-icon (click)="close()" class="cursor-pointer icon-4" name="mdi:close"></app-icon>
                </div>
                <div class="text-xs text-gray-800" [innerHTML]="'blockades.' + type + '.description' | translate"></div>
            </div>
        </div>
    </ng-container>
</ng-container>
